<template>
  <div>
    <el-form
      :model="formData"
      :rules="rules"
      ref="formRef"
      label-width="220px"
      class="detail-form"
      label-suffix="："
    >
      <div class="info-area">
        <div class="title" style="border: none">应用信息</div>
        <!-- <el-form-item label="应用名称" prop="title">
          <span>{{ formData.title }}</span>
        </el-form-item> -->
        <el-form-item label="应用分类" prop="appType">
          <span>{{ formData.appType | typeDesc(appTypeList) }}</span>
        </el-form-item>
        <!-- <el-form-item
          label="是否为运营商应用"
          prop="operatorApp"
          v-if="formData.sourceType === 1"
        >
          {{ formData.operatorApp === 0 ? "否" : "是" }}
        </el-form-item> -->
        <el-form-item label="默认语言" prop="defaultLanguage">
          <span>{{ defaultLanguageDesc }}</span>
        </el-form-item>
        <el-form-item label="上架类型" prop="releaseType">
          <el-radio-group
            v-model="formData.releaseType"
            @change="handleChangeReleaseType"
            :disabled="disabled || sourceDev"
          >
            <el-radio :label="1">普通</el-radio>
            <!-- <el-radio :label="2" v-show="formData.appType === 1">预约</el-radio>
            <el-radio :label="3" v-show="formData.appType === 1">内测</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="APP ID" prop="appId">
          <span>{{ formData.appId }}</span>
        </el-form-item>
        <el-form-item label="secretKey" prop="SecretKey">
          <span>{{ formData.secretKey }}</span>
        </el-form-item>
        <el-form-item :label="'是否上架Google Play'" prop="isGoogle">
          <el-radio-group :disabled="disabled" v-model="formData.isGoogle">
            <el-radio :label="1">
              {{ "是" }}
            </el-radio>
            <el-radio :label="2">
              {{ "否" }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="formData.isGoogle == 1"
          :label="'应用在Google Play的下载次数'"
          prop="popularity"
        >
          <el-radio-group :disabled="disabled" v-model="formData.popularity">
            <el-radio :label="5"> {{ "&lt;100k" }} </el-radio>
            <el-radio :label="10"> {{ "100k-1M" }} </el-radio>
            <el-radio :label="15"> {{ "1M-10M" }} </el-radio>
            <el-radio :label="20"> {{ ">10M" }} </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="formData.isGoogle == 1"
          :label="'应用在Google Play的评分'"
          prop="performance"
        >
          <el-radio-group :disabled="disabled" v-model="formData.performance">
            <el-radio :label="5"> {{ "&lt;3.5" }} </el-radio>
            <el-radio :label="10"> {{ "3.5-4.0" }} </el-radio>
            <el-radio :label="15"> {{ "4.1-4.5" }} </el-radio>
            <el-radio :label="20"> {{ ">4.5" }} </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="info-area">
        <div class="title">兼容设备</div>
        <!-- <el-form-item label="兼容设备" prop="supportedDevice">
          <span>{{
            formData.supportedDevice | typeDesc(supportedDeviceList)
          }}</span>
        </el-form-item> -->
        <el-form-item label="支持版本" prop="supportedVersion">
          <div class="disfr">
            <el-form-item prop="minVersion">
              <el-select
                v-model="formData.minVersion"
                placeholder="最低"
                clearable
                :disabled="disabled"
                :class="{ highLight: formData.minVersionLight }"
              >
                <el-option
                  v-for="item in versionList"
                  :key="item.key"
                  :value="item.key"
                  :label="item.value"
                  :disabled="
                    formData.maxVersion != '' && item.key > formData.maxVersion
                  "
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="maxVersion">
              <el-select
                v-model="formData.maxVersion"
                placeholder="最高"
                clearable
                :disabled="disabled"
                :class="{ highLight: formData.maxVersionLight }"
              >
                <el-option
                  v-for="item in versionList"
                  :key="item.key"
                  :value="item.key"
                  :label="item.value"
                  :disabled="
                    formData.minVersion != '' && item.key < formData.minVersion
                  "
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form-item>
      </div>

      <div class="info-area">
        <div class="title">可本地化基础信息</div>
        <div class="sub-desc">
          正确填写应用多语言信息，有助于增加应用在应用市场的曝光。1、如果您没有为各语言版本添加本地图片文件，则系统将使用默认语言版本的图片文件。2
          、输入的文本方式展示，不支持html标签
        </div>
        <el-form-item label="语言" prop="currentLanguage">
          <el-select
            filterable
            size="small"
            placeholder="请选择语言"
            @change="handleChangeLanguage"
            v-model="formData.currentLanguage"
          >
            <el-option
              v-for="item in localLanguageList"
              :key="item.languageId"
              :label="item.languageName"
              :value="item.languageId"
              >{{
                item.languageId == formData.defaultLanguage
                  ? item.languageName + " - 默认"
                  : item.languageName
              }}</el-option
            >
          </el-select>
          <el-dropdown
            trigger="click"
            :hide-on-click="false"
            :disabled="disabled"
          >
            <el-button
              size="small"
              type="primary"
              :disabled="disabled"
              class="ml10"
              >管理语言列表</el-button
            >
            <el-dropdown-menu slot="dropdown">
              <el-checkbox-group v-model="localLanguage" class="lg-area">
                <el-checkbox
                  :disabled="item.languageId === formData.defaultLanguage"
                  v-for="item in languageList"
                  :key="item.languageId"
                  :label="item.languageId"
                  >{{ item.languageName }}</el-checkbox
                >
              </el-checkbox-group>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown
            trigger="click"
            :hide-on-click="false"
            v-if="localLanguageList.length > 1"
            :disabled="disabled"
          >
            <el-button
              size="small"
              type="primary"
              :disabled="disabled"
              class="ml10"
              >更改默认语言</el-button
            >
            <el-dropdown-menu slot="dropdown">
              <el-radio-group
                v-model="formData.defaultLanguage"
                class="lg-area"
              >
                <el-radio
                  v-for="item in localLanguageList"
                  :key="item.languageId"
                  :label="item.languageId"
                  >{{ item.languageName }}</el-radio
                >
              </el-radio-group>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="remark-text">{{ currentLanguageDesc }}</div>
          <div class="remark-text">切换语言可维护相应语言的应用信息</div>
        </el-form-item>
        <el-form
          style="padding-top: 15px"
          :model="languageBasicData"
          :rules="lgRules"
          ref="lgFormRef"
          label-width="200px"
          class="detail-form"
          label-suffix="："
        >
          <el-form-item label="应用名称" prop="appName">
            <el-input
              size="small"
              :maxlength="64"
              show-word-limit
              :disabled="disabled"
              v-model="languageBasicData.appName"
              placeholder="请输入应用名称"
              :class="{ highLight: languageBasicData.titleLight }"
            ></el-input>
            <div class="remark-text">{{ currentLanguageDesc }}</div>
          </el-form-item>
          <el-form-item label="应用一句话简介（小编推荐）" prop="briefIntro">
            <el-input
              size="small"
              :maxlength="80"
              show-word-limit
              :disabled="disabled"
              v-model="languageBasicData.briefIntro"
              placeholder="请输入应用一句话简介"
              :class="{ highLight: languageBasicData.briefIntroLight }"
            ></el-input>
            <div class="remark-text">{{ currentLanguageDesc }}</div>
          </el-form-item>
          <el-form-item label="应用介绍" prop="intro">
            <el-input
              size="small"
              :rows="10"
              type="textarea"
              :maxlength="800"
              show-word-limit
              :disabled="disabled"
              v-model="languageBasicData.intro"
              placeholder="请输入应用介绍"
              :class="{ highLight: languageBasicData.introLight }"
            ></el-input>
            <div class="remark-text">{{ currentLanguageDesc }}</div>
          </el-form-item>
          <el-form-item label="新版本特性" prop="newFeature">
            <el-input
              size="small"
              :rows="10"
              type="textarea"
              :maxlength="500"
              show-word-limit
              :disabled="disabled"
              v-model="languageBasicData.newFeature"
              placeholder="新版本特性，500字以内"
              :class="{ highLight: languageBasicData.newFeatureLight }"
            ></el-input>
            <div class="remark-text">{{ currentLanguageDesc }}</div>
          </el-form-item>
          <el-form-item label="应用图标" prop="appIcon">
            <UploadImg
              class="appIcon"
              v-model="languageBasicData.appIcon"
              limSizeUnit="KB"
              :limSize="200"
              :limWidth="162"
              :limHeight="162"
              :disabled="disabled"
              :acceptType="['png', 'jpeg', 'jpg']"
              @success="
                (file) => handleSuccessUpload(file, 'image', 'appIcon', 0)
              "
              @delete="(accessPath) => handleDeleteFile(accessPath, 'image')"
              :class="{ highLight: languageBasicData.appIconLight }"
            ></UploadImg>
            <div class="remark-text">{{ currentLanguageDesc }}</div>
            <div class="remark-text">
              图标需为正方形。大小：200KB以内；尺寸：162*162px；格式：PNG、JPG、JPEG。
            </div>
          </el-form-item>
          <el-form-item
            label="应用截图和视频"
            prop="apkImgList"
            :rules="[
              { validator: validateApkList, trigger: 'change' },
              { required: true },
            ]"
          >
            <div class="remark-text">
              您可以在这里上传应用详情页展示的素材。介绍图是必填素材，其他素材可选填，如果您提交了其他素材，可能会让应用在应用市场客户端有更好的展效果
            </div>
            <el-tabs v-model="activeName">
              <el-tab-pane name="1">
                <span slot="label" class="required-class">应用介绍截图</span>
                <el-radio-group
                  v-model="languageBasicData.imgMode"
                  :disabled="disabled"
                  @click.native.capture="
                    handleClickRadio(
                      $event,
                      'image',
                      languageBasicData.imgMode,
                      disabled
                    )
                  "
                >
                  <el-radio label="horizontal">横向截图</el-radio>
                  <el-radio label="vertical">纵向截图</el-radio>
                </el-radio-group>
                <div>
                  <UploadImg
                    :class="{
                      'upload-vertical':
                        languageBasicData.imgMode === 'vertical',
                      'upload-horizontal':
                        languageBasicData.imgMode !== 'vertical',
                      highLight: languageBasicData.apkImgListLight[count - 1],
                    }"
                    v-for="count in 5"
                    :key="count"
                    v-model="languageBasicData.apkImgList[count - 1]"
                    :limSize="2"
                    :disabled="disabled"
                    :limWidth="
                      languageBasicData.imgMode === 'vertical' ? 720 : 1280
                    "
                    :limHeight="
                      languageBasicData.imgMode === 'vertical' ? 1280 : 720
                    "
                    :acceptType="['png', 'jpeg', 'jpg']"
                    @success="
                      (file) =>
                        handleSuccessUpload(
                          file,
                          'image',
                          'apkImgList',
                          count - 1
                        )
                    "
                    @delete="
                      (accessPath) => handleDeleteFile(accessPath, 'image')
                    "
                  ></UploadImg>
                </div>
                <div class="remark-text">{{ currentLanguageDesc }}</div>
                <div class="remark-text">
                  {{
                    ` 需要上传3~5张。大小：2M以内；尺寸：${
                      languageBasicData.imgMode === "vertical"
                        ? "720*1280"
                        : "1280*720"
                    }px；格式：PNG、JPG、JPEG。`
                  }}
                </div>
              </el-tab-pane>
              <el-tab-pane label="应用介绍视频" name="2">
                <el-radio-group
                  size="small"
                  v-model="languageBasicData.videoMode"
                  :disabled="disabled"
                  @click.native.capture="
                    handleClickRadio(
                      $event,
                      'video',
                      languageBasicData.videoMode,
                      disabled
                    )
                  "
                >
                  <el-radio label="horizontal">横向视频</el-radio>
                  <el-radio label="vertical">纵向视频</el-radio>
                </el-radio-group>
                <div style="display: flex">
                  <div>
                    <div class="remark-text">视频封面</div>
                    <UploadImg
                      :disabled="disabled"
                      :class="{
                        'upload-vertical':
                          languageBasicData.videoMode === 'vertical',
                        'upload-horizontal':
                          languageBasicData.videoMode !== 'vertical',
                        highLight: languageBasicData.appVideoImgLight,
                      }"
                      :limWidth="
                        languageBasicData.videoMode === 'vertical' ? 720 : 1280
                      "
                      :limHeight="
                        languageBasicData.videoMode === 'vertical' ? 1280 : 720
                      "
                      v-model="languageBasicData.appVideoImg"
                      :acceptType="['png', 'jpeg', 'jpg']"
                      @success="
                        (file) =>
                          handleSuccessUpload(file, 'video', 'appVideoImg', 0)
                      "
                      @delete="
                        (accessPath) => handleDeleteFile(accessPath, 'video')
                      "
                    ></UploadImg>
                  </div>
                  <div>
                    <div class="remark-text">上传视频</div>
                    <UploadImg
                      :disabled="disabled"
                      :class="{
                        'upload-vertical':
                          languageBasicData.videoMode === 'vertical',
                        'upload-horizontal':
                          languageBasicData.videoMode !== 'vertical',
                        highLight: languageBasicData.appVideoUrlLight,
                      }"
                      v-model="languageBasicData.appVideoUrl"
                      :isVideo="true"
                      :limSize="500"
                      :acceptType="['mov', 'mp4']"
                      @success="
                        (file) =>
                          handleSuccessUpload(file, 'video', 'appVideo', 1)
                      "
                      @delete="
                        (accessPath) => handleDeleteFile(accessPath, 'video')
                      "
                    ></UploadImg>
                  </div>
                </div>
                <div class="remark-text">{{ currentLanguageDesc }}</div>
                <div class="remark-text">
                  {{
                    `视频封面：请上传视频封面，分辨率为${
                      languageBasicData.videoMode === "vertical"
                        ? "720*1280"
                        : "1280*720"
                    }，大小：2MB 以内，格式：PNG、JPG、JPEG。`
                  }}
                </div>
                <div class="remark-text">
                  {{
                    `上传视频：请上传介绍视频，建议分辨率为${
                      languageBasicData.videoMode === "vertical"
                        ? "720*1280"
                        : "1280*720"
                    }，宽高比为${
                      languageBasicData.videoMode === "vertical"
                        ? "9:16"
                        : "16:9"
                    }，支持MOV、MP4格式，大小要求500MB以内，视频长度15秒-2分钟`
                  }}
                </div>
              </el-tab-pane>
              <!-- 暂时隐藏应用推荐视频 -->
              <el-tab-pane label="应用推荐视频" name="3" v-if="false">
                <div style="display: flex">
                  <div>
                    <div class="remark-text">视频封面</div>
                    <UploadImg
                      :disabled="disabled"
                      class="upload-horizontal"
                      :class="{
                        highLight: languageBasicData.appPushVideoImgLight,
                      }"
                      v-model="languageBasicData.appPushVideoImg"
                      :acceptType="['png', 'jpeg', 'jpg']"
                      :limSize="2"
                      :limWidth="1440"
                      :limHeight="810"
                      @success="
                        (file) =>
                          handleSuccessUpload(
                            file,
                            'video',
                            'appPushVideoImg',
                            0
                          )
                      "
                      @delete="
                        (accessPath) => handleDeleteFile(accessPath, 'video')
                      "
                    ></UploadImg>
                  </div>
                  <div>
                    <div class="remark-text">上传视频</div>
                    <UploadImg
                      :disabled="disabled"
                      class="upload-horizontal"
                      :class="{
                        highLight: languageBasicData.appPushVideoUrlLight,
                      }"
                      v-model="languageBasicData.appPushVideoUrl"
                      :isVideo="true"
                      :limSize="500"
                      :acceptType="['mov', 'mp4']"
                      @success="
                        (file) =>
                          handleSuccessUpload(file, 'video', 'appPushVideo', 1)
                      "
                      @delete="
                        (accessPath) => handleDeleteFile(accessPath, 'video')
                      "
                    ></UploadImg>
                  </div>
                </div>
                <div class="remark-text">{{ currentLanguageDesc }}</div>
                <div class="remark-text">
                  视频封面：分辨率为1440*810px，大小：2MB以内，格式：PNG、JPG、JPEG。
                </div>
                <div class="remark-text">
                  上传视频：应用推荐视频，建议分辨率为1280*720，宽高比为16:9，支持MOV、MP4格式，文件大小要求500MB以内，视频长度15秒-2分钟
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
        </el-form>
      </div>
      <div class="info-area">
        <div class="title">应用分类</div>
        <div class="sub-desc">
          请选择正确的分类，如果您的分类和应用市场的分类管理规则不一致，我们可能会予以修改并以邮件通知您。
        </div>
        <el-form-item label="应用分类" prop="appCategory">
          <el-cascader
            size="small"
            :options="categoryList"
            :disabled="disabled"
            :props="{
              value: 'cateId',
              label: 'cateName',
              children: 'categoryHierarchyList',
            }"
            v-model="formData.appCategory"
            placeholder="请选择应用分类"
            :class="{ highLight: formData.appClassifyIdsLight }"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="" prop="gameType" v-if="formData.appType === 1">
          <el-radio-group v-model="formData.gameType" :disabled="disabled">
            <el-radio
              v-for="item in appSubTypeList"
              :key="item.key"
              :label="item.key"
              >{{ item.value }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="应用标签" prop="labels">
          <el-select
            multiple
            filterable
            size="small"
            :disabled="disabled"
            v-model="formData.labels"
            :class="{ highLight: formData.labelsLight }"
          >
            <el-option
              v-for="item in labelList"
              :key="item.labelId"
              :value="item.labelId"
              :label="item.labelName"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="info-area">
        <div class="title">开发者服务信息</div>
        <div class="sub-desc">
          您的开发者服务信息会向您应用发布地区的用户公开，其中您的客服邮箱可能会共享给第三方评级机构，目的均是用于接收用户反馈和意见等。
        </div>
        <!-- <el-form-item label="供应商名称" prop="supplyName">
          <el-input
            size="small"
            :disabled="disabled"
            v-model="formData.supplyName"
            placeholder="请输入供应商名称"
            :class="{ highLight: formData.supplyNameLight }"
          ></el-input>
        </el-form-item>
        <el-form-item label="供应商名称（英文）" prop="supplyNameEn">
          <el-input
            size="small"
            :disabled="disabled"
            v-model="formData.supplyNameEn"
            placeholder="请输入供应商名称（英文）"
            :class="{ highLight: formData.supplyNameEnLight }"
          ></el-input>
        </el-form-item> 
        <el-form-item label="开发者名称" prop="devName">
          <el-input
            size="small"
            :disabled="disabled"
            v-model="formData.devName"
            placeholder="请输入开发者名称"
            :class="{ highLight: formData.devNameLight }"
          ></el-input>
        </el-form-item>
        <el-form-item label="开发者名称（英文）" prop="devNameEn">
          <el-input
            size="small"
            :disabled="disabled"
            v-model="formData.devNameEn"
            placeholder="请输入开发者名称（英文）"
            :class="{ highLight: formData.devNameEnLight }"
          ></el-input>
        </el-form-item>-->
        <el-form-item
          :label="'应用是否为实名认证企业开发'"
          prop="isVerifiedCorpDev"
        >
          <el-radio-group
            :disabled="disabled"
            v-model="formData.isVerifiedCorpDev"
          >
            <el-radio :label="1">
              {{ "是" }}
            </el-radio>
            <el-radio :label="2">
              {{ "否" }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="'实际开发者名称'" prop="devName" required>
          <el-input
            :disabled="disabled"
            v-model="formData.devName"
            placeholder="实际开发者名称"
          ></el-input>
          <span class="sub-desc ml10"
            >请填写该应用的实际开发者对应公司的名称，该名称将在应用商店展示。</span
          >
        </el-form-item>
        <el-form-item :label="'实际开发者名称(英文)'" prop="devNameEn" required>
          <el-input
            :disabled="disabled"
            v-model="formData.devNameEn"
            placeholder="实际开发者名称"
          ></el-input>
          <span class="sub-desc ml10"
            >请填写该应用的实际开发者对应公司的英文名称，该名称将在应用商店展示。</span
          >
        </el-form-item>
        <el-form-item
          :label="'授权书'"
          prop=""
          required
          v-if="formData.isVerifiedCorpDev === 2"
        >
          <div class="personHand fc">
            <div class="disfr">
              <el-form-item
                prop="authDocUrl"
                class="fc mlr20"
                style="margin-left: 0"
              >
                <div class="fc">
                  <UploadFilePreview
                    :title="'请上传授权书'"
                    :disabled="disabled"
                    :limSize="20"
                    v-model="formData.authDocUrl"
                    type="image"
                    :acceptType="['png', 'jpg', 'jpeg', 'pdf']"
                    @success="
                      (res) => {
                        formData.authDocUrl = res.data.accessPath;
                      }
                    "
                  >
                  </UploadFilePreview>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          :label="'授权书有效期'"
          prop="authDocDate"
          required
          v-if="formData.isVerifiedCorpDev == 2"
        >
          <el-date-picker
            type="daterange"
            v-model="formData.authDocDate"
            :range-separator="'至'"
            value-format="yyyy-MM-dd"
            :end-placeholder="'结束日期'"
            :start-placeholder="'开始日期'"
            :disabled="disabled"
          ></el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="开发者地址" prop="devAddress" v-show="false">
          <el-input
            size="small"
            :disabled="disabled"
            v-model="formData.devAddress"
            placeholder="请输入开发者地址"
            :class="{ highLight: formData.devAddressLight }"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="应用官网" prop="webUrl">
          <el-input
            size="small"
            :disabled="disabled"
            v-model="formData.webUrl"
            placeholder="请输入URL，以https://开头"
            :class="{ highLight: formData.webUrlLight }"
          ></el-input>
        </el-form-item>
        <el-form-item label="客服邮箱" prop="customerServiceEmail">
          <el-input
            size="small"
            :disabled="disabled"
            v-model="formData.customerServiceEmail"
            placeholder="请输入客服邮箱"
            :class="{ highLight: formData.customerServiceEmailLight }"
          ></el-input>
        </el-form-item>
        <el-form-item label="客服电话" prop="customerServiceTel">
          <el-input
            show-word-limit
            maxlength="20"
            size="small"
            :disabled="disabled"
            placeholder="请输入客服电话"
            v-model="formData.customerServiceTel"
            :class="{ highLight: formData.customerServiceTelLight }"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import UploadImg from "@/components/UploadImg.vue";
import UploadFilePreview from "@/components/UploadFilePreview.vue";
import {
  appTypeList,
  appSubTypeList,
  versionList,
  supportedDeviceList,
} from "@/common/constant/constant.js";
import { getCategoryList, getLabelList } from "./indexServe.js";
export default {
  components: { UploadImg, UploadFilePreview },
  props: {
    appInfoData: {
      type: Object,
      default() {
        return {};
      },
    },
    languageList: {
      type: Array,
      default() {
        return [];
      },
    },
    operationType: { default: "" },
  },
  created() {
    this.getCategoryList();
    this.getLabelList();
    this.initData(this.appInfoData);
  },
  data() {
    const checkTel = (rule, value, callback) => {
      // 校验电话
      // eslint-disable-next-line no-useless-escape
      const patt = /^[0-9 \-]+$/;
      if (value && !patt.test(value)) {
        return callback(new Error("请输入正确的电话"));
      } else {
        callback();
      }
    };
    const checkEmail = (rule, value, callback) => {
      // 校验邮箱
      // eslint-disable-next-line no-useless-escape
      const patt = /^([a-zA-Z0-9_\.\-]+@([a-zA-Z0-9\-]+\.)+[a-z]+)$/;
      if (value && !patt.test(value)) {
        return callback(new Error("请输入正确的邮箱地址"));
      } else {
        callback();
      }
    };
    const checkEnglish = (rule, value, callback) => {
      // 校验英文
      // eslint-disable-next-line no-useless-escape
      const patt = /^[a-zA-Z \s]+$/;
      if (value && !patt.test(value)) {
        return callback(new Error("请输入正确的英文名称"));
      } else {
        callback();
      }
    };
    const checkUrl = (rule, value, callback) => {
      // 校验url,以https开头
      // eslint-disable-next-line no-useless-escape
      const patt = /(http|https):\/\/([\w.]+\/?)\S*/;
      if (value && !patt.test(value)) {
        return callback(new Error("请输入正确的网址"));
      } else {
        callback();
      }
    };
    return {
      appTypeList,
      appSubTypeList,
      supportedDeviceList,
      versionList,
      languageBasicData: {}, // 可多语言的数据
      formData: {},
      rules: {
        title: [{ required: true, message: "请输入应用名称" }],
        appType: [{ required: true, message: "请选择应用分类" }],
        defaultLanguage: [{ required: true, message: "请选择默认语言" }],
        releaseType: [{ required: true, message: "请选择上架类型" }],
        currentLanguage: [{ required: true, message: "请选择语言" }],
        customerServiceEmail: [
          {
            trigger: ["blur", "change"],
            validator: checkEmail,
          },
        ],
        operatorApp: [{ required: true, message: "请选择" }],
        customerServiceTel: [{ validator: checkTel }],
        webUrl: [{ validator: checkUrl }],
        appCategory: [{ required: true, message: "请选择应用分类" }],
        supplyName: [{ required: true, message: "请输入实际开发者名称" }],
        supplyNameEn: [
          { required: true, message: "请输入实际开发者名称（英文）" },
        ],
        devName: [{ required: true, message: "请输入实际开发者名称" }],
        devNameEn: [
          { required: true, message: "请输入实际开发者名称（英文）" },
        ],
        authDocDate: [{ required: true, message: "请选择授权书有效期" }],
      },
      lgRules: {
        appName: [{ required: true, message: "请输入应用名称" }],
        briefIntro: [{ required: true, message: "请输入一句话介绍" }],
        intro: [{ required: true, message: "请输入应用介绍" }],
        appIcon: [{ required: true, message: "请上传应用图标" }],
        apkImgList: [{ required: true, message: "请上传应用截图" }],
      },
      activeName: "1",
      categoryList: [],
      labelList: [],
      localLanguage: [], // 本地化语言id
      odlLanguage: "", // 保存切换语言时，旧语言的信息
    };
  },
  computed: {
    currentLanguageDesc() {
      const currentLanguage = this.formData.currentLanguage;
      let desc =
        (this.languageList.find((i) => i.languageId === currentLanguage) || {})
          .languageName || "";
      if (currentLanguage === this.formData.defaultLanguage) {
        desc += " - 默认";
      }
      return desc;
    },
    defaultLanguageDesc() {
      return (
        (
          this.languageList.find(
            (i) => i.languageId === this.formData.defaultLanguage
          ) || {}
        ).languageName || ""
      );
    },
    localLanguageList() {
      const list = this.languageList.filter((i) =>
        this.localLanguage.includes(i.languageId)
      );
      return list;
    },
    disabled() {
      return this.operationType === "view" ? true : false;
    },
    sourceDev() {
      return this.appInfoData.sourceType === 0; //开发者平台过来的数据
    },
  },
  methods: {
    // 事件监听
    handleChangeReleaseType(val) {
      this.$emit("changeType", val);
    },
    handleClickRadio(e, type, val, disabled) {
      if (disabled) {
        return false;
      }
      e.preventDefault();
      let msg = "";
      if (type === "image") {
        msg = "切换截图类型将会覆盖已上传截图资源和视频资源，是否继续切换？";
        // val === "vertical"
        //   ? "您已上传纵向截图，如果切换为横向上传资源则会覆盖已上传资源，是否继续切换方向？"
        //   : "您已上传横向截图，如果切换为竖向上传资源则会覆盖已上传资源，是否继续切换方向？";
      } else {
        msg = "切换视频类型将会覆盖已上传视频资源和截图资源，是否继续切换？";
        // val === "vertical"
        //   ? "您已上传纵向视频，如果切换为横向上传资源则会覆盖已上传资源，是否继续切换方向？"
        //   : "您已上传横向视频，如果切换为竖向上传资源则会覆盖已上传资源，是否继续切换方向？";
      }
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // if (type === "image") {
          this.languageBasicData.imgMode =
            val === "vertical" ? "horizontal" : "vertical";
          this.languageBasicData.apkImgList = [];
          //imgType图片类型 图标:1，横向截图:2，竖向截图:3
          const imgType = val === "vertical" ? 3 : 2;
          this.languageBasicData.imageForms =
            this.languageBasicData.imageForms.filter(
              (i) => i.imgType != imgType
            );
          // } else {
          this.languageBasicData.videoMode =
            val === "vertical" ? "horizontal" : "vertical";
          this.languageBasicData.appVideoImg = "";
          this.languageBasicData.appVideoUrl = "";
          //videoType应用介绍视频横向:10，应用介绍视频竖向:11, 推荐视频:12，横向视屏封面:26，纵向视屏封面:27，推荐视屏封面:28
          const videoTypeList = val === "vertical" ? [11, 27] : [10, 26];
          this.languageBasicData.videoForms =
            this.languageBasicData.videoForms.filter(
              (i) => !videoTypeList.includes(i.videoType)
            );
          // }
        })
        .catch(() => {
          const modeVal = val === "horizontal" ? "horizontal" : "vertical";
          if (type === "image") {
            this.languageBasicData.imgMode = modeVal;
          } else {
            this.languageBasicData.videoMode = modeVal;
          }
        });
    },
    // 文件上传成功后，存储文件信息
    handleSuccessUpload(file, type, name, sort) {
      this.$refs.lgFormRef.clearValidate(); // 清除校验效果
      if (type === "image") {
        let imgType = 1;
        if (name === "apkImgList") {
          imgType = this.languageBasicData.imgMode === "vertical" ? 3 : 2;
        }
        const imgObj = {
          ...file,
          imgType, //图片类型 图标:1，横向截图:2，竖向截图:3
          sort, //显示顺序
          size: file.fileSize,
        };
        const imageForms = (this.languageBasicData.imageForms || []).filter(
          (i) => !(i.imgType == imgObj.imgType && i.sort == imgObj.sort)
        );
        imageForms.push(imgObj);
        this.languageBasicData.imageForms = imageForms;
      } else if (type === "video") {
        let videoType;
        if (name === "appVideo") {
          videoType = this.languageBasicData.videoMode === "vertical" ? 11 : 10;
        } else if (name === "appVideoImg") {
          videoType = this.languageBasicData.videoMode === "vertical" ? 27 : 26;
        } else if (name === "appPushVideoImg") {
          videoType = 28;
        } else if (name === "appPushVideo") {
          videoType = 12;
        }
        const videoObj = {
          ...file,
          videoType, //videoType应用介绍视频横向:10，应用介绍视频竖向:11, 推荐视频:12，横向视屏封面:26，纵向视屏封面:27，推荐视屏封面:28
          sort, //显示顺序
          size: file.fileSize,
        };
        const videoForms = (this.languageBasicData.videoForms || []).filter(
          (i) => !(i.videoType == videoObj.videoType && i.sort == videoObj.sort)
        ); // 过滤原来的数据
        videoForms.push(videoObj);
        this.languageBasicData.videoForms = videoForms;
      }
    },
    // 删除文件信息
    handleDeleteFile(accessPath, type) {
      if (type === "image") {
        const imageForms = (this.languageBasicData.imageForms || []).filter(
          (i) => i.accessPath !== accessPath
        );
        this.languageBasicData.imageForms = imageForms;
      } else if (type === "video") {
        const videoForms = (this.languageBasicData.videoForms || []).filter(
          (i) => i.accessPath !== accessPath
        );
        this.languageBasicData.videoForms = videoForms;
      }
    },
    handleChangeLanguage(lg) {
      const oldLang = this.odlLanguage;
      // oldLang不存在时，不需要进行任何操作
      if (oldLang || oldLang == 0) {
        this.odlLanguage = lg; // 更新odlLanguage的信息
        this.setLanguageInfo(oldLang, this.languageBasicData); // 保存当前语言的信息
        const currentData = this.getLanguageInfo(lg); // 获取切换后的语言信息
        if (currentData) {
          // 若切换后语言的基础信息已存在，将该信息显示出来(深拷贝)
          this.languageBasicData = JSON.parse(JSON.stringify(currentData));
        } else {
          // 若切换后的语言不存在信息（新增语言信息），则显示默认语言信息
          // const defaultData = this.getLanguageInfo(
          //   this.formData.defaultLanguage
          // );
          // this.languageBasicData = JSON.parse(JSON.stringify(defaultData));
          // 目前不需要显示默认语言信息
          this.languageBasicData = {
            imgMode: "horizontal",
            videoMode: "horizontal",
            apkImgListLight: [],
            apkImgList: [], // 应用截图url列表
            imageForms: [], // 传给后端的图片集合列表
            videoForms: [], // 传给后端的视频集合列表
          };
        }
      }
    },
    // 后端请求
    // 获取应用分类列表
    async getCategoryList() {
      const res = await getCategoryList();
      if (res && res.code === 0) {
        this.categoryList = res.data;
      }
    },
    // 获取应用标签列表
    async getLabelList() {
      const res = await getLabelList();
      if (res && res.code === 0) {
        this.labelList = res.data;
      }
    },
    // 其他
    initData(data) {
      //初始改数组数据，否则若父组件未传入，会报错
      const newData = JSON.parse(JSON.stringify(data)); // 深拷贝
      const languageInfo = {
        imgMode: "horizontal",
        videoMode: "horizontal",
        apkImgListLight: [],
        apkImgList: [], // 应用截图url列表
        imageForms: [], // 传给后端的图片集合列表
        videoForms: [], // 传给后端的视频集合列表
      };
      const formData = {
        defaultLanguage: "", // 默认语言,不能给一个具体的数据，不然会触发watch，造成bug
        gameType: 1, // 游戏类型
        languageInfos: [],
        labels: [], // 标签集合
        isVerifiedCorpDev: 1, //应用是否为实名认证企业开发 1:是 2:否
        isGoogle: 1,
        ...newData,
      };
      if (formData.authDocUrl) {
        formData.isVerifiedCorpDev = 2;
      }
      if (newData.authDocStart && newData.authDocEnd) {
        formData.authDocDate = [newData.authDocStart, newData.authDocEnd];
      }
      if (data.localLanguage.length !== 0) {
        this.localLanguage = data.localLanguage; // 可本地化的语言id列表
      } else {
        this.localLanguage = [formData.defaultLanguage];
      }
      formData.currentLanguage = formData.defaultLanguage; // 默认语言赋值给当前编辑的语言
      this.odlLanguage = formData.currentLanguage; // 填充odlLanguage
      this.formData = formData; // 填充formData
      this.formData.releaseType = Number(this.formData.releaseType);
      const languageBasicData =
        this.getLanguageInfo(formData.currentLanguage) || languageInfo; // 当前语言的基础数据
      if (!languageBasicData.appName) {
        languageBasicData.appName = data.appName;
      }
      this.languageBasicData = {
        imgMode: "horizontal",
        videoMode: "horizontal",
        ...languageBasicData,
      };
    },
    // 获取可多语言的信息数据
    getLanguageInfo(lg) {
      return this.formData.languageInfos.find((i) => i.language === lg);
    },
    // 设置可多语言的信息数据
    setLanguageInfo(lg, lgData) {
      if (!lg && lg != 0) return;
      const data = JSON.parse(JSON.stringify(lgData)); //深拷贝
      data.language = lg; //更改数据里的语言，以防lgData里残留其他语言数据
      const index = this.formData.languageInfos.findIndex(
        (i) => i.language === lg
      );
      if (index !== -1) {
        // 原本存在该语言的数据则修改
        this.formData.languageInfos.splice(index, 1, data);
      } else {
        // 原本不存在该语言的数据则添加
        this.formData.languageInfos.push(data);
      }
    },
    getLanguageDesc(lgId) {
      return (
        (this.languageList.find((i) => i.languageId === lgId) || {})
          .languageName || ""
      );
    },
    // 暴露给父组件设置releaseType的值
    setReleaseType(releaseType) {
      this.formData.releaseType = releaseType;
    },
    // 暴露给父组件获取表单数据
    getFormData() {
      const data = this.formData;
      data.appInfoId = data.id;
      if (data.authDocDate && data.authDocDate.length > 0) {
        data.authDocStart = data.authDocDate[0];
        data.authDocEnd = data.authDocDate[1];
      }
      if (data.isVerifiedCorpDev === 1) {
        data.authDocUrl = "";
      }
      data.compatiableDevice = data.supportedDevice; // 保存提交时，兼容性设备换字段名
      if (data.appCategory) {
        data.appClassifyIds = data.appCategory.join("|");
        if (data.gameType) {
          data.appClassifyIds += "," + data.gameType;
        }
      }
      data.language = data.defaultLanguage;
      data.releaseInfoId = data.appReleaseId;
      this.setLanguageInfo(data.currentLanguage, this.languageBasicData); // 保存当前语言的信息
      // 只需要将已选的语言信息发送给后端
      this.formData.languageInfos = this.formData.languageInfos.filter((i) =>
        this.localLanguage.includes(i.language)
      );
      return data;
    },
    // 暴露给父组校验表单数据
    validate() {
      let data = false;
      let lgData = false;
      let msg = "";
      this.$refs.lgFormRef.validate((valid) => {
        lgData = valid;
      });
      this.$refs.formRef.validate((valid) => {
        data = valid;
      });
      if (data && lgData) {
        const { minVersion, maxVersion } = this.formData;
        if (minVersion && !maxVersion) {
          msg = "请选择最高版本，或删除最低版本";
          data = false;
        } else if (!minVersion && maxVersion) {
          msg = "请选择最低版本，或删除最高版本";
          data = false;
        }
        // 检查多语言信息是否完整
        this.setLanguageInfo(
          this.formData.currentLanguage,
          this.languageBasicData
        ); // 保存当前语言的信息
        for (let lg of this.formData.languageInfos) {
          if (this.localLanguage.includes(lg.language)) {
            if (!lg.appName || !lg.briefIntro || !lg.intro || !lg.appIcon) {
              lgData = false;
              const lgDesc = this.getLanguageDesc(lg.language);
              msg = `${lgDesc}的本地化信息维护不完全；`;
              break;
            } else if (lg.apkImgList.filter((url) => url).length < 3) {
              lgData = false;
              const lgDesc = this.getLanguageDesc(lg.language);
              msg = `请上传3~5张${lgDesc}的应用截图；`;
              break;
            }
          }
        }
        // 检查是否已维护所有已经选择的语言
        const languageInfosIds = this.formData.languageInfos.map(
          (i) => i.language
        );
        for (let lgId of this.localLanguage) {
          if (!languageInfosIds.includes(lgId)) {
            lgData = false;
            const lgDesc = this.getLanguageDesc(lgId);
            msg = `${lgDesc}的本地化信息维护不完全；`;
            break;
          }
        }
      }
      return { valid: data && lgData, msg };
    },
    validateApkList(rule, apkImgList, callback) {
      const imgList = apkImgList.filter((url) => url);
      if (imgList.length < 3) {
        callback(new Error("请上传3~5张应用截图"));
      } else {
        callback();
      }
    },
  },
  watch: {
    appInfoData(data) {
      this.initData(data);
    },
    localLanguage(list) {
      // 更新store中的localLanguageIdList，供其他组件使用（如预约）
      this.$store.commit("updateState", ["localLanguageIdList", list]);
    },
  },
};
</script>
<style lang="scss">
.detail-form {
  .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled)
    .el-radio__inner {
    box-shadow: none !important;
  }
}
</style>
<style scoped lang="scss">
.detail-form {
  .el-input,
  .el-textarea {
    width: 500px;
  }
}
.info-area {
  .title {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
    padding-top: 30px;
    border-top: 1px solid #dfdfdf;
  }
  .sub-desc {
    font-size: 14px;
    line-height: 1.5;
    color: #858b90;
    width: 90%;
    margin-bottom: 20px;
  }
}
.remark-text {
  color: #858b90;
  font-size: 13px;
  line-height: 1.5;
}
.appIcon {
  padding: 5px;
  box-sizing: border-box;
}
.upload-horizontal {
  width: 240px;
  height: 135px;
  margin-right: 10px;
  padding: 5px;
  box-sizing: border-box;
}
.upload-vertical {
  width: 135px;
  height: 240px;
  margin-right: 10px;
  padding: 5px;
  box-sizing: border-box;
}
.lg-area {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  label {
    margin-bottom: 10px;
  }
}
.required-class {
  &::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}
</style>
